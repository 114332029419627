<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <div class="text-center">
        <v-img
          max-height="300"
          max-width="300"
          src="/img/foods/menunggu.png"
        />
        <h2>Pesanan Anda Segera Diproses</h2>
        <h3>Selamat Menunggu</h3>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>